// Home.js
import React from "react";
import PageHelmet from "../components/PageHelmet";
import { HomeOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { Row } from "antd";
import AHService from "../components/atoms/AHService";
import LocalShipping from "@mui/icons-material/LocalShipping";
import ConstructionTwoToneIcon from "@mui/icons-material/ConstructionTwoTone";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";
import MonitorHeartTwoToneIcon from "@mui/icons-material/MonitorHeartTwoTone";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import DirectionsCar from "@mui/icons-material/DirectionsCar";

const cards = [
  {
    id: 1,
    name: "Osobní automobily",
    description:
      "V naší nabídce najdete osobní automobily různých světových značek.",
    img: <DirectionsCar style={{ color: "#000c78" }} />,
    route: "/nabidka",
  },
  {
    id: 2,
    name: "Užitkové automobily",
    description:
      "Zákazníkům u užitkových automobilů nabízíme možnost odečíst DPH. Problémem není ani leasing nebo spotřebitelský úvěr na financování.",
    img: <LocalShipping style={{ color: "#654200" }} />,
    route: "/nabidka",
  },
  {
    id: 3,
    name: "Servis a Pneuservis",
    description: "Nabízíme komplexní servis a pneuservis pro všechny typy vozidel.",
    img: <ConstructionTwoToneIcon style={{ color: "#3c3c3c" }} />,
    route: "/kontakt",
  },
  {
    id: 4,
    name: "Pojistné služby a poradenství",
    description:
      "Společnost poskytuje poradenství a nabízí uzavření povinného ručení a havarijního pojištění přizpůsobené individuálním potřebám zákazníka.",
    img: <ReceiptLongTwoToneIcon style={{ color: "#01717d" }} />,
    route: "/kontakt",
  },
  {
    id: 5,
    name: "Diagnostika vozu",
    description:
      "Provádíme servis a diagnostiku vozů všech značek s minimální čekací dobou.",
    img: <MonitorHeartTwoToneIcon style={{ color: "#017d16" }} />,
    route: "/kontakt",
  },
  {
    id: 6,
    name: "Montáž tažného zařízení",
    description:
      "Montáž tažného zařízení, střešních nosičů, příčníků za super ceny bez nutnosti prohlídky STK.",
    img: <TipsAndUpdatesTwoToneIcon style={{ color: "#37017d" }} />,
    route: "/kontakt",
  },
];

function Home() {
  return (
    <>
      <PageHelmet title="Domů" favicon={<HomeOutlined />} />
      <Content className="page" style={{textAlign: 'center'}}>
        <h1>Kdo jsme?</h1>
        <p>
        Jsme spolehlivý partner pro nákup kvalitních ojetých automobilů v
        malebném srdci kraje Vysočina - Novém Městě na Moravě. </p>
        
        <p>Jsme hrdí na to,
        že můžeme nabízet širokou škálu nehavarovaných dovezených ojetých
        vozidel ze zemí Evropské unie. </p>
        
        <p>
        S vášní k automobilům a pečlivostí ve
        výběru naší nabídky vám přinášíme jistotu, že každý automobil v našem
        autobazaru splňuje vysoké standardy kvality.
        </p>
        {/* <AHService title={cards[0].name} description={cards[0].description} icon={<CarTwoTone className='service-name-icon'/>}/> */}

        <h1 style={{paddingTop:15, paddingBottom:0, marginBottom: 10 }}>Naše služby</h1>
        <Row justify={"space-evenly"} style={{ rowGap: 15, paddingBottom:15}}>
        {cards.map((card) => (
          <AHService
          key={card.id}
          title={card.name}
          description={card.description}
          icon={card.img}
          route={card.route }
          />
        ))}          
        </Row>
      </Content>
    </>
  );
}

export default Home;
