import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageHelmet = ({ title, favicon }) => (
  <Helmet>
    <title>{title} | AutoHolubka.cz</title>
    {favicon}
  </Helmet>
);

PageHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  favicon: PropTypes.element
};

export default PageHelmet;