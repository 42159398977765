import { message } from "antd";
import axios from "axios";
import Cookies from 'js-cookie';

// if deploayed use jakubkachlik.savana-hosting.cz otherwise localhost:3003


axios.defaults.baseURL = "https://autoholubka.cz"; // Change the port number as needed
// axios.defaults.baseURL = "http://localhost:3003"; // Change the port number as needed

export const insertData = async () => {
  await axios.get("/api/insert-car");
  message.success("Auto přidáno!");
};

export const getCars = async (filter) => {

  const response = await axios.get("/api/get-cars", {
    params: {
      filter: filter
    }
  });
  return response;
};

export const getCar = async (id) => {
  const response = await axios.get(`/api/get-car/${id}`);
  return response;
};

export const getImages = async (carPhotoLink) => {
  const response = await axios.get(`/api/images/${carPhotoLink}`);
  return response;
};

export const getAllImages = async (id) => {
  const response = await axios.get(`/api/get-all-images/${id}`);
  console.log(response);
  return response;
}

export const getProducers = async () => {
  const response = await axios.get("/api/get-producers");
  console.log(response);
  return response;
}

export const deleteCar = async (id) => {
  const response = await axios.delete(`/api/delete-car/${id}`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("jwt")}`
    }
  });
  console.log(response);
  message.success("Auto smazano!");
}

export const login = async (values) => {
  const response = await axios.get("/api/login", {params:values});
  console.log(response);
  return response;
}

export const sendMail = async (formFields) => {
  console.log(formFields)
  const response = await axios.post("/api/send-mail", formFields, {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  )
  console.log(response)
  return response
}

export const parseXmlFile = async () => {
  const response = await axios.get("/api/trigger-parser", {
  });
  console.log(response);
  return response;
}

export const deleteAllCars = async () => {
  const response = await axios.get("/api/delete-all-cars");
  console.log(response);
  return response;
}