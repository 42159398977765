import { Content } from "antd/es/layout/layout";
import React from "react";
import { Col, Row } from "antd";
// import { insertData } from "./api";
import PageHelmet from "../components/PageHelmet";
import Link from "antd/es/typography/Link";

function Contact() {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   setWindowWidth(window.innerWidth);
  // }, window.innerWidth)

  return (
    <>
      <PageHelmet title="Kontakt" />
      <Content className="page">
        <h1 className="page-name">Kontakt</h1>
        <h3 className="page-description">
          Kde nás najdete a jak se s námi spojit?
        </h3>
        <Row style={{ rowGap: "30px" }}>
          <Col lg={8} md={24}>
            {/* <Button title="SEND API" onClick={() => insertData()}>
            Vlozit auto
          </Button> */}
            <h2>Adresa</h2>
            <p>Vančurova 1548, 592 31 Nové Město na Moravě</p>
            <h2>Telefon</h2>
            <p>
              <Link href="tel:+420776176283">+420 776 176 283</Link>
            </p>
            <p>
              <Link href="tel:+420608767919">+420 608 767 919</Link>
            </p>
            <h2>Email</h2>
            <p>
              <Link href="mailto:autoholubka@seznam.cz">
                autoholubka@seznam.cz
              </Link>
            </p>
            <h2>Autoservis</h2>
            <p>
              <Link href="tel:+420774020136">+420 774 020 136</Link>
            </p>
            <p>Vančurova 1548, Nové Město na Moravě, 592 31</p>
            <h2>Provozní doba</h2>
            <Row>
              <Col lg={5} md={6} sm={7} xs={10}>
                <p>Po - Pá:</p>
              </Col>
              <Col>
                <p> 08:00 — 16:00</p>
              </Col>
            </Row>
            <Row>
              <Col lg={5} md={6} sm={7} xs={10}>
                <p>So:</p>
              </Col>
              <Col>
                <p> 09:00 — 13:00 (po telefonické domluvě)</p>
              </Col>
            </Row>
          </Col>
          <Col
            lg={16}
            md={24}
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6069.3021315308315!2d16.07974238553329!3d49.57040422045115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d9de8b81b0d9d%3A0x449f2e3a4a4b4f08!2zVmFuxI11cm92YSAxNTQ4LCA1OTIgMzEgTm92w6kgTcSbc3RvIG5hIE1vcmF2xJs!5e0!3m2!1scs!2scz!4v1706196338075!5m2!1scs!2scz" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
            }}
          ></Col>
        </Row>
      </Content>
    </>
  );
}

export default Contact;
