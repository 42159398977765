import { Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

const HorizontalMenu = ({ menuItems, justify }) => {
  const location = useLocation();

  const handleLogout = () => {
    console.log("logging out");
    Cookies.remove("jwt");
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.reload();
  };

  return (
    <Menu
      selectedKeys={[location.pathname]}
      style={{ paddingRight: 20, justifyContent: justify }}
      theme="dark"
      mode="horizontal"
      items={menuItems.map((item) => {
        if (item.key === "logout") {
          // If it's the second item (index is 0-based)
          return {
            key: item.path,
            label: item.title,
            icon: item.icon,
            onClick: handleLogout,
          };
        } else {
          return {
            key: item.path,
            label: <Link to={item.path}>{item.title}</Link>,
            icon: item.icon,
          };
        }
      })}
    ></Menu>
  );
};

HorizontalMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  justify: PropTypes.string,
};

export default HorizontalMenu;
