// About.js
import {
  Button,
  Card,
  Col,
  Image,
  Modal,
  Popconfirm,
  Row,
  Select,
  Skeleton,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import {
  deleteAllCars,
  deleteCar,
  getCars,
  getProducers,
  parseXmlFile,
} from "../components/api";
import PageHelmet from "../components/PageHelmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import SelectedCarDetail from "./SelectedCarDetail";
import { thousandsSeparator } from "../utils/formatingUtil";
import FromToSelect from "../components/FromToSelect";
import PropTypes from "prop-types";

const defaultFilter = {
  priceFilter: ["", ""],
  yearFilter: ["", ""],
  kmFilter: ["", ""],
  producers: [],
};

function Cars({ isAdmin }) {
  const [listOfCars, setListOfCars] = useState([]);
  const [isCarListFetching, setIsCarListFetching] = useState(false);
  const [carModalOpen, setCarModalOpen] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [priceFilter, setPriceFilter] = useState(defaultFilter.priceFilter);
  const [yearFilter, setYearFilter] = useState(defaultFilter.yearFilter);
  const [kmFilter, setKmFilter] = useState(defaultFilter.kmFilter);
  const [availableProducers, setAvailableProducers] = useState(
    defaultFilter.producers
  );

  const [selectedProducers, setSelectedProducers] = useState([]);

  const route = useParams();
  const navigate = useNavigate();

  async function carsGetter() {
    try {
    // setIsLoading(true);
    setIsCarListFetching(true);
    const producers = await getProducers();
    setAvailableProducers(producers.data);
    console.log(availableProducers);
    const resp = await getCars();
    console.log(resp);
    setListOfCars(resp.data);
    setIsCarListFetching(false);
    if (route.carId) {
      setCarModalOpen(true);
      // const car = resp.data.find(car => car.id === route.carId)
      // setSelectedCar(car)
    }
  } catch (error) {
    console.error(error)
  }
    // setIsLoading(false);
  }

  useEffect(() => {
    carsGetter();
  }, []);

  useEffect(() => {
    if (route.carId) {
      setCarModalOpen(true);
      // if(listOfCars.length > 0) {
      //   console.log("changed")
      //   setSelectedCar(listOfCars.find(car => car.id === route.carId))
      // }
    } else {
      setCarModalOpen(false);
      // setSelectedCar(null)
    }
  }, [route]);

  const getProducersOptions = () => {
    return availableProducers ? availableProducers.map((producer) => {
      return { label: producer, value: producer };
    }) : [];
  };

  const handleFilterSearchClicked = async () => {
    const filter = {
      priceFrom: priceFilter[0],
      priceTo: priceFilter[1],
      yearFrom: yearFilter[0],
      yearTo: yearFilter[1],
      kilometersFrom: kmFilter[0],
      kilometersTo: kmFilter[1],
      producers: selectedProducers,
    };
    setIsFilterActive(true);
    setIsCarListFetching(true);
    const producers = await getProducers();
    setAvailableProducers(producers.data);
    console.log(availableProducers);
    const filteredCars = await getCars(filter);
    setListOfCars(filteredCars.data);
    setIsCarListFetching(false);
  };

  const handleFiltersRemoved = async () => {
    setPriceFilter(defaultFilter.priceFilter);
    setYearFilter(defaultFilter.yearFilter);
    setKmFilter(defaultFilter.kmFilter);
    setSelectedProducers(defaultFilter.producers);
    setIsCarListFetching(true);

    // const filter = {
    //   priceFrom: "",
    //   priceTo: "",
    //   yearFrom: "",
    //   yearTo: "",
    //   kilometersFrom: "",
    //   kilometersTo: "",
    //   producers: [],
    // };

    setIsFilterActive(false);
    const producers = await getProducers();
    setAvailableProducers(producers.data);
    console.log(availableProducers);
    const filteredCars = await getCars();
    setListOfCars(filteredCars.data);
    setIsCarListFetching(false);
  };

  const handleParseNewCarsClicked = async () => {
    await parseXmlFile()
  }

  const handleDeleteAllCarsClicked = async () => {
    await deleteAllCars();
  }

  return (
    <>
      <PageHelmet title="Aktuální nabídka" />
      <Content className="page">
        <h1 className="page-name">Aktuální nabídka</h1>
        <Row justify='center'>
          { isAdmin && <Popconfirm title='Opravdu chcete smazat vsechna auta?' onConfirm={handleDeleteAllCarsClicked}>

        <Button danger>Smazat vše</Button>
          </Popconfirm> }
          { isAdmin && <Popconfirm title='Skutecne jste nahrali nove auto?' onConfirm={handleParseNewCarsClicked}>
        <Button>Zpracovat nove nahrana auta</Button>
          </Popconfirm> }
        </Row>
        <p className="page-description">Níže naleznete aktuální seznam nabízených vozů. V případě zájmu nás neváhejte <Link to='/kontakt'>kontaktovat</Link>.</p>
        <Row justify='center'>

        <div>
        <Col>
          <FromToSelect
            label="Cena"
            from={priceFilter[0]}
            to={priceFilter[1]}
            setter={setPriceFilter}
            unit={" Kč"}
            step={1000}
          />
          <FromToSelect
            label="Rok výroby"
            from={yearFilter[0]}
            to={yearFilter[1]}
            setter={setYearFilter}
            step={1}
          />
          <FromToSelect
            label="Počet kilometrů"
            from={kmFilter[0]}
            to={kmFilter[1]}
            setter={setKmFilter}
            unit={" km"}
            step={1000}
          />
        </Col>
        <Col style={{ paddingBottom: 20 }}>
          <Row align={"bottom"}>
            <Col span={24} md={5}>
              <p style={{ paddingRight: 10 }}>Značka:</p>
            </Col>
            <Col>
              <Select
                style={{ minWidth: "20em" }}
                mode="multiple"
                allowClear
                placeholder="Zvolte značku vozidla"
                options={getProducersOptions()}
                onChange={(value) => setSelectedProducers(value)}
                value={selectedProducers}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button onClick={handleFilterSearchClicked}>Hledat</Button>
          {isFilterActive && (
            <Button
              danger
              type="primary"
              style={{ marginLeft: 5 }}
              onClick={handleFiltersRemoved}
            >
              Zrušit filtr
            </Button>
          )}
        </Col>
        </div>
        </Row>
        <Row className="cars-row">
          {isCarListFetching ? (
            <>
                      <p style={{ paddingTop: 20 }}>
            Nepodařilo se načíst seznam vozů? Naši nabídku vozů nalezenete také na <a href="http://www.tipcars.com/adresar-autoholubka-cz-sro-1994.html" target="_blank" rel="noreferrer">TipCars</a>.
          </p>
              <Skeleton active />
              <Skeleton active />
            </>
          ) : listOfCars.length === 0 ? (
            <h2>Žádná auta nebyla nalezena</h2>
          ) : (
            listOfCars.map((car) => {
              const parsedCar = car;
              return (
                <Col key={parsedCar.id}>
                  <Card
                    className="car-widget"
                    title={car.name}
                    hoverable
                    onClick={() => 
                      navigate(`/nabidka/${parsedCar.id}`)
                    }
                  >
                    <Image
                      preview={false}
                      src={`data:image/jpeg;base64,${parsedCar.photos.photo.b64._}`}
                      alt={car.name}
                      style={{ width: "100%", borderRadius: 5}}
                    />
                    <p>
                      {parsedCar.producer} {parsedCar.type},{" "}
                      {parsedCar["model-supplement"]}
                    </p>
                    <p>{thousandsSeparator(parsedCar["sales-price"])} Kč</p>
                    <Button>Detail</Button>
                    {isAdmin && (
                      <Popconfirm
                        title="Opravdu chcete smazat toto auto?"
                        onConfirm={(event) => {
                          event.stopPropagation();
                          deleteCar(parsedCar.id);
                          carsGetter()
                        }}
                        onCancel={(event) => {
                          event.stopPropagation();
                        }
                        }
                        okText="Ano"
                        cancelText="Ne"
                      >
                        <Button
                          style={{ marginLeft: 5 }}
                          danger
                          type="primary"
                          onClick={(event) => {
                            // This stops the click event from propagating when the delete button is clicked
                            event.stopPropagation();
                          }}
                        >
                          Smazat
                        </Button>
                      </Popconfirm>
                    )}
                  </Card>
                </Col>
              );
            })
          )}
        </Row>
        {carModalOpen && (
          <Modal
            title="Detail vozu"
            className="car-detail-modal"
            width={"90vw"}
            footer={false}
            onOk={() => {}}
            open={carModalOpen}
            onCancel={() => {
              navigate("/nabidka");
            }}
          >
            <Content style={{ height: "80vh" }}>
              <SelectedCarDetail />
            </Content>
          </Modal>
        )}
        {/* <Image.PreviewGroup>

        {listOfImages.map((image) => {
          return (
            <Image key={image} width={200} src={image}/>
          );        
        })}
        </Image.PreviewGroup> */}
      </Content>
    </>
  );
}

Cars.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Cars;
