export const carDetails = [
  { propName: "producer", label: "Výrobce" },
  { propName: "type", label: "Typ vozu" },
  { propName: "constr-year", label: "Rok výroby" },
  { propName: "content_dec", label: "Obsah motoru" },

  { propName: "km", label: "Počet najetých kilometrů" },
  { propName: "category", label: "Kategorie" },
  { propName: "constr", label: "Typ" },
  { propName: "state", label: "Země původu" },

  { propName: "Service_book", label: "Servisní knížka" },
  { propName: "color", label: "Barva" },
  { propName: "doors-number", label: "Počet dveří" },
  { propName: "etc", label: "Stav" },
  { propName: "engine_spec", label: "Dodatek k motoru" },
  { propName: "fuel", label: "Palivo" },
  { propName: "gear", label: "Převodovka" },
  { propName: "gen_equip", label: "Pohon" },
  { propName: "me", label: "Platnost měření emisí do" },
  { propName: "seats", label: "Počet míst k sezení" },
  { propName: "specification", label: "Specifikace motoru" },
  { propName: "stk", label: "Platnost technické kontroly" },
  { propName: "to_operation", label: "V provozu od" },
  { propName: "remark", label: "Poznámka", isArray: true },
  { propName: "extra", label: "Extra", isArray: true }
];
